import { assistantApi } from 'api'

export interface Props {
  file_names: string[]
}

export interface UploadUrl {
  url: string
  name: string
}

export interface UploadUrlResponse {
  prefix: string
  urls: UploadUrl[]
}

export const fetchFileUploadUrls = ({ file_names }: Props) => {
  const params = new URLSearchParams()
  file_names.forEach(el => {
    params.append('fileNames', el)
  })

  return assistantApi.get<UploadUrlResponse>(`/files/upload-urls?${params.toString()}`)
}

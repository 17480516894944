import { WppTag, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { KeyboardEvent, FormEvent, MutableRefObject, useMemo } from 'react'
import { OverflowList } from 'react-overflow-list'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Mentions } from 'components/assistant/chat/mentions/Mentions'
import { QuestionInput } from 'components/assistant/chat/questionInput/QuestionInput'
import { AssistantTabs } from 'constants/ui'
import { useChatContext } from 'hooks/useChatContext'
import { useHasPermissions } from 'hooks/useHasPermissions'

interface Props {
  onSubmitQuestion: (event: FormEvent<HTMLFormElement>) => void
  question: string
  answerIsLoading: boolean
  onQuestionKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void
  mentionOptionsMemo: { id: string; display: string; description: string }[]
  questionCharCount: number
  onInput: () => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  mentionDropdownVisible: boolean
  mentionClicked: (mention: string) => void
  inputRef: React.RefObject<HTMLDivElement>
  defaultValue: string
  selectedMentionId: string
  enableEdit: boolean
  mentionsContainerRef: React.RefObject<HTMLDivElement>
  mentionsRef: MutableRefObject<any>
}

const ChatQuestionInput = ({
  onSubmitQuestion,
  question,
  answerIsLoading,
  onQuestionKeyDown,
  mentionOptionsMemo,
  questionCharCount,
  onInput,
  onMouseDown,
  mentionDropdownVisible,
  mentionClicked,
  defaultValue,
  selectedMentionId,
  inputRef,
  enableEdit,
  mentionsContainerRef,
  mentionsRef,
}: Props) => {
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { tab, conversation } = useChatContext()

  const ItemRenderer = (item: string, index: number) => {
    // Remove the file path from the file name
    return <WppTag key={index} label={item.replace(/^[^/]+\//, '')} variant="neutral" />
  }

  const OverflowRenderer = (items: any[]) => {
    return (
      // Remove the file path from the file name and join all the file names
      <WppTooltip text={items.map(str => str.replace(/^[^/]+\//, '')).join('\n')}>
        <WppTag label={`+${items.length}`} variant="neutral" maxLabelLength={20000} />
      </WppTooltip>
    )
  }

  const isReadOnly = useMemo(
    () => tab === AssistantTabs.HISTORY && conversation?.ragFiles && conversation.ragFiles.length > 0,
    [conversation?.ragFiles, tab],
  )

  return (
    <form
      className={clsx(
        styles.questionInputWrapper,
        hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
      )}
      onSubmit={onSubmitQuestion}
    >
      {conversation?.ragFiles && conversation.ragFiles.length > 0 && (
        <OverflowList
          collapseFrom="end"
          minVisibleItems={0}
          items={conversation.ragFiles}
          itemRenderer={ItemRenderer}
          overflowRenderer={OverflowRenderer}
          className={styles.overflowList}
        />
      )}

      {!isReadOnly && (
        <div className={styles.inputBorder}>
          <QuestionInput
            inputRef={inputRef}
            enableEdit={enableEdit}
            defaultValue={defaultValue}
            onInput={onInput}
            onQuestionKeyDown={onQuestionKeyDown}
            onMouseDown={onMouseDown}
            question={question}
            questionCharCount={questionCharCount}
            answerIsLoading={answerIsLoading}
          />
          {mentionOptionsMemo.length > 0 && mentionDropdownVisible && (
            <Mentions
              mentionOptions={mentionOptionsMemo}
              questionInputRef={inputRef}
              selectedMentionId={selectedMentionId}
              onClick={mentionClicked}
              mentionsContainerRef={mentionsContainerRef}
              mentionsRef={mentionsRef}
            />
          )}
        </div>
      )}
    </form>
  )
}

export default ChatQuestionInput

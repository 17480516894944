import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseQuery<F extends QueryFetcher, S>({
  queryKey,
  fetcher,
  selector,
}: {
  queryKey: string
  fetcher: F
  selector: (response?: QueryFetcherResponse<F>) => S
}) {
  return function useCustomQuery(
    options: { params?: QueryFetcherParams<F> } & Omit<
      UseQueryOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherResponse<F>, [string, QueryFetcherParams<F>]>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select'
    > = {},
  ) {
    const { params = {} as any, enabled = true, ...queryOptions } = options

    const { data, isLoading, ...rest } = useQuery({
      queryKey: [queryKey, params],
      ...{ enabled, ...queryOptions },
      queryFn: ({ signal }: { signal: any }) => fetcher(params)(signal) as QueryFetcherResponse<F>,
    })

    return {
      ...rest,
      isLoading: enabled && isLoading,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}

export function createLazyQuery<F extends QueryFetcher, S>({
  queryKey,
  fetcher,
  selector,
}: {
  queryKey: string
  fetcher: F
  selector: (response?: QueryFetcherResponse<F>) => S
}) {
  return function useCustomQuery(
    options: { initialParams?: QueryFetcherParams<F> } & Omit<
      UseQueryOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherResponse<F>, [string, QueryFetcherParams<F>]>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select'
    > = {},
  ) {
    const { initialParams = {} as QueryFetcherParams<F>, ...queryOptions } = options
    const [params, setParams] = useState<QueryFetcherParams<F>>(initialParams)
    const [runQuery, setRunQuery] = useState(false)

    const trigger = useCallback((newParams?: QueryFetcherParams<F>) => {
      if (newParams) {
        setParams(newParams)
      }
      setRunQuery(true)
    }, [])

    const query = useQuery({
      queryKey: [queryKey, initialParams || params],
      ...{ ...queryOptions },
      queryFn: ({ signal }: { signal: any }) => fetcher(params)(signal) as QueryFetcherResponse<F>,
      enabled: runQuery,
      select: selector,
      networkMode: 'always',
    })

    useEffect(() => {
      if (query.isSuccess || query.isError || query.isFetched) {
        setRunQuery(false)
      }
    }, [query])

    const isSuccess = useMemo(() => query.isSuccess && query.isFetched, [query])

    return [query, trigger, isSuccess] as const
  }
}

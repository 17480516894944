import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import styles from 'components/assistant/chat/chatBubble/markdownText/MarkdownText.module.scss'

interface Props {
  text: string
}

export const MarkdownText = ({ text }: Props) => {
  return (
    <ReactMarkdown className={styles.markdownText} remarkPlugins={[remarkGfm]}>
      {text}
    </ReactMarkdown>
  )
}

import { assistantApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'

export interface Pill {
  id: string
  name: string
  tenantId: string
  tooltip: string
  content: string
  fullpageAppId: string | null
  isMention: boolean
}

export const fetchPills = ({
  page,
  itemsPerPage,
  tenantId,
  fullpageAppId,
  isMention,
}: PaginationParams<{ tenantId: string; fullpageAppId?: string | null; isMention: boolean | null }>) =>
  assistantApi.get<PaginatedResponse<Pill>>('/pills', {
    params: { page, itemsPerPage, tenantId, fullpageAppId, isMention },
  })

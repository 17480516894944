import { useContext } from 'react'

import { FileUploadContext } from 'contexts/FileUploadContext'

export const useFileUploadContext = () => {
  const context = useContext(FileUploadContext)
  if (!context) {
    throw new Error('useFileUploadContext must be used within a FileUploadProvider')
  }

  return {
    ...context,
  }
}

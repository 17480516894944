import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
// import { ConversationDto } from 'types/dto/ConversationDto'
import { TaskDtoCreate } from 'types/dto/TaskDto'

export interface Props {
  userId: string
  mdId: string | null
  messages: string[]
  filesPrefix?: string
}

export const createConversation = ({
  userId,
  messages,
  mdId,
  filesPrefix,
}: Props): CancelableRequestProducer<TaskDtoCreate> => {
  return assistantApi.post('/chats', { userId, messages, mdId, filesPrefix })
}
